import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Background from 'src/assets/photos/campus.jpg';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { primary, white } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1000;

const MainBanner = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.background}>
            <div style={styles.overlay}>
                <div style={styles.mainTitle}>
                    <div style={styles.contentWrap}>
                        <Text
                            title_large
                            black
                            color={white}
                            customStyle={{ margin: 10 }}
                        >
                            Yale University
                        </Text>
                        <Text
                            title_medium
                            bold
                            color={white}
                            customStyle={{ margin: 10 }}
                        >
                            Science Olympiad
                        </Text>
                        <Text
                            title_small
                            medium_bold
                            color={white}
                            customStyle={{ margin: 10 }}
                        >
                            February 8th, 2025
                        </Text>
                        {/* <Button
                            type="round_verybig"
                            rel="noopener noreferrer"
                            href="/results"
                        >
                            Results
                        </Button> */}
                        {/* <Button
                            type="round_verybig"
                            rel="noopener noreferrer"
                            href="/tournament"
                        >
                            Tournament Info
                        </Button> */}
                        <Button
                            type="round_verybig"
                            rel="noopener noreferrer"
                            href="/results"
                        >
                            2025 Results
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    background: {
        backgroundColor: 'rgba(0, 0, 0, .5)',
        backgroundImage: `url( ${Background} )`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
        minHeight: 900
    } as CSSProperties,
    overlay: {
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        minHeight: 900
    } as CSSProperties,
    mainTitle: {
        color: 'white',
        textAlign: windowWidth > MOBILE_BREAKPOINT ? 'left' : 'center',
        padding: windowWidth > MOBILE_BREAKPOINT ? '25em 10rem' : '10em .1rem'
    } as CSSProperties,
    contentWrap: {
        borderLeft: `8px solid ${primary}`,
        height: 210,
        paddingLeft: 15
    } as CSSProperties
});

export default MainBanner;
